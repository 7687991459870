import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getProperty = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'property',
    method: 'get'
  })

  return data
}
const putProperty = async (requestBody) => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'property',
    method: 'put',
    body: requestBody
  })

  return data
}

export default { getProperty, putProperty }
