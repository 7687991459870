import { useForm } from 'react-hook-form'

import { IdsIcon, IdsActionButton } from '@ids/react'
import { Navigation, If } from '@itau-loans-www/shopping/src/components'
import { useLead } from '@itau-loans-www/shopping/src/hooks'
import ProposalLayout from 'layouts/Proposal'
import { goTo } from 'utils'

import Shimmer from 'components/Shimmer'

import usePropertyDataUnavailable from './hooks'
import './unavailable.scss'

const Unavailable = ({ pageContext }) => {
  const {
    leadData: { name }
  } = useLead()

  const { handleSubmit } = useForm({
    mode: 'onChange'
  })

  const { onSubmit, loading } = usePropertyDataUnavailable()

  return (
    <ProposalLayout pageContext={pageContext}>
      <If
        condition={loading}
        renderIf={
          <>
            <Shimmer
              margin="10px 0 40px 0"
              widthDesktop="85%"
              height="64px"
              heightDesktop="30px"
            />
            <Shimmer margin="0 0 20px 0" height="176px" />
            <Shimmer
              margin="0 0 200px 0"
              marginDesktop="0 0 300px 0"
              width="40%"
              widthDesktop="20%"
              height="48px"
            />
            <Shimmer widthDesktop="50%" height="50px" />
          </>
        }
        renderElse={
          <div className="unavailable">
            <h3 className="proposal-title">{`${name}, o valor mínimo para esse empréstimo é de:`}</h3>
            <div className="unavailable__card">
              <IdsIcon variant="primary">consorcio_outline</IdsIcon>
              <div className="unavailable__header">
                <strong>Valor mínimo:</strong>
                <strong>R$ 53.000,00</strong>
              </div>
              <p className="unavailable__description">
                Portanto seu imóvel não se adequa para esse empréstimo
              </p>
            </div>
            <IdsActionButton
              variant="secondary"
              className="unavailable__button"
              onClick={() => goTo('property-data-customize')}
            >
              alterar o valor
              <IdsIcon size="S">arrow_right_base</IdsIcon>
            </IdsActionButton>
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <Navigation
                submit
                nextStep
                nextButtonText="Empréstimos disponíveis"
                nextButtonId="property-data-unavailable-next-btn"
              />
            </form>
          </div>
        }
      />
    </ProposalLayout>
  )
}

export default Unavailable
